

//---------------------------
//確認画面へボタン
//---------------------------
// $(".contact-form__submit-link").click(function () {
//   setTimeout(function(){
//     let classVal = $('form').attr('class');
//     let classVals = classVal.split(' ');
   
//     for (var i = 0; i < classVals.length; i++) {
//       console.log(classVals[i]);
//     }

//     if(classVals[1] == "custom-wpcf7c-confirmed"){
//       $('.step1').removeClass('-on');
//       $('.step2').addClass('-on');

//       $('.contact-form__personal-wrap').hide();
//     }else{
//       $('.step2').removeClass('-on');
//       $('.step1').addClass('-on');

//       $('.contact-form__personal-wrap').show();
//     }

//   },1500);
  
// });

//---------------------------
//戻るボタンクリック
//---------------------------
$(".c-form-submit__back").click(function () {

  $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
  $('.step2').removeClass('-on');
  $('.step1').addClass('-on');

  //$('.contact-form__personal-wrap').show();

});

//---------------------------
//エラーありの場合 戻る
//---------------------------
const wpcf7Elm = document.querySelector( '.wpcf7' );

if(wpcf7Elm) {
  const position = $('form.wpcf7-form').offset().top;
  wpcf7Elm.addEventListener( 'wpcf7invalid', function( event ) {
    let speed = 1000;
    jQuery("html, body").animate({scrollTop:position}, speed, "swing");
  }, false );
}
 


//---------------------------
//メールアドレスコピペ禁止
//---------------------------
$("input[type=email]").on('copy cut paste',function(e) {
  e.preventDefault();
});